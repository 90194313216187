import React, { memo } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

const formHTML = `
<style type="text/css" scoped>
  .mauticform-row.mauticform-required .mauticform-label:after { color: #e32; content: " *"; display: inline; }
</style>
  <div id="mauticform_wrapper_prelaunch">
    <form autocomplete="false" role="form" method="post" action="https://mautica.accessorizetheworld.com/form/submit?formId=1" id="mauticform_prelaunch" data-mautic-form="prelaunch" enctype="multipart/form-data">
      <div class="mauticform-error mb-1 text-red-600" id="mauticform_prelaunch_error"></div>
      <div class="mauticform-message" id="mauticform_prelaunch_message"></div>
      <div class="mauticform-page-wrapper mauticform-page-1 flex flex-col" data-mautic-form-page="1">
        <div class="block sm:flex flex-row my-0 sm:my-4">
          <div id="mauticform_prelaunch_first_name" first-name-field data-validate="first_name" data-validation-type="text" class="mauticform-row mauticform-text mauticform-field-1 mauticform-required flex-1 mr-0 sm:mr-2 my-4 sm:my-0">
            <label id="mauticform_label_prelaunch_first_name" for="mauticform_input_prelaunch_first_name" first-name-label class="mauticform-label block text-sm font-medium text-gray-700">First Name</label>
            <input id="mauticform_input_prelaunch_first_name" name="mauticform[first_name]" value="" placeholder="Your First Name"w-full first-name-input class="mauticform-input mt-1 p-2 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md h-40p" type="text">
            <div class="mauticform-errormsg text-red-600 mt-1 text-xs" style="display: none;" >First Name is required</div>
          </div>
          <div id="mauticform_prelaunch_last_name" last-name-field data-validate="last_name" data-validation-type="text" class="mauticform-row mauticform-text mauticform-field-2 mauticform-required flex-1 my-4 sm:my-0">
            <label id="mauticform_label_prelaunch_last_name" for="mauticform_input_prelaunch_last_name" last-name-label class="mauticform-label block text-sm font-medium text-gray-700">Last Name</label>
            <input id="mauticform_input_prelaunch_last_name" name="mauticform[last_name]" value="" placeholder="Your  Last Name" last-name-input class="mauticform-input mt-1 p-2 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md h-40p" type="text">
            <div class="mauticform-errormsg text-red-600 mt-1 text-xs block" style="display: none;">Last Name is required</div>
          </div> 
        </div>
       
        <div id="mauticform_prelaunch_email" email-field data-validate="email" data-validation-type="email" class="mauticform-row mauticform-email mauticform-field-3 mauticform-required my-4">
          <label id="mauticform_label_prelaunch_email" for="mauticform_input_prelaunch_email" email-label class="mauticform-label block text-sm font-medium text-gray-700">Email</label>
          <input id="mauticform_input_prelaunch_email" name="mauticform[email]" value="" placeholder="Your  Email" email-input class="mauticform-input mt-1 p-2 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md h-40p" type="email">
          <div class="mauticform-errormsg text-red-600 mt-1 text-xs" style="display: none;">Email is required</div>
        </div>

        <div id="mauticform_prelaunch_phone" phone-number-field class="mauticform-row mauticform-tel mauticform-field-4 my-4">
          <label id="mauticform_label_prelaunch_phone" for="mauticform_input_prelaunch_phone" phone-number-label class="mauticform-label block text-sm font-medium text-gray-700">Phone</label>
          <input id="mauticform_input_prelaunch_phone" name="mauticform[phone]" value="" placeholder="Your Phone Number" phone-number-input class="mauticform-input mt-1 p-2 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md h-40p" type="tel">
          <span class="mauticform-errormsg text-red-600 mt-1" style="display: none;"></span>
        </div>

        <div id="mauticform_prelaunch_phone" phone-number-field class="mauticform-row mauticform-tel mauticform-field-4 col-span-6 my-2">
          By submitting this form, I agree to allow Accessorizetheworld notify me when it launches, and also send me relevant newsletters when necessary.
        </div>

        <div id="mauticform_prelaunch_submit" class="mauticform-row mauticform-button-wrapper mauticform-field-5 col-span-6 sm:col-span-4">
            <button type="submit" name="mauticform[submit]" id="mauticform_input_prelaunch_submit" value="" class="inline-flex justify-center py-2 px-10 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Submit</button>
        </div>
      </div>

      <input type="hidden" name="mauticform[formId]" id="mauticform_prelaunch_id" value="1">
      <input type="hidden" name="mauticform[return]" id="mauticform_prelaunch_return" value="">
      <input type="hidden" name="mauticform[formName]" id="mauticform_prelaunch_name" value="prelaunch">
    </form>
  </div>
`

const Home = memo(() => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Accessorize The World</title>
      <link rel="canonical" href="http://accessorizetheworld.com" />
      <meta name="description" content="Nested component" />
    </Helmet>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center h-screen mx-4">
      <div className="p-4 hidden lg:block">
        <StaticImage src="../images/designs.png" alt="A kitten" />
      </div>
      <div>
        <div className="px-4 bg-white rounded-lg md:mr-4">
          <div>
            <Link to="/">
              <StaticImage
                src="../images/title.png"
                alt="A kitten"
                height={100}
                width={500}
              />
            </Link>
          </div>
          <div className="ml-4">
            <div id="mauticform_wrapper_prelaunch">
              <div className="py-4 font-title text-xl md:text-2xl font-semibold">
                Sign up to get first crack at our exciting store. We will send
                you a discount code when we launch
              </div>

              <div
                className="px-4 py-5 sm:p-6"
                dangerouslySetInnerHTML={{ __html: formHTML }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
))

export default Home
